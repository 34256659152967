<template>
  <div class="record-mod1">
    <div class="tabbar-box" v-if="renderSearch">
      <img
        src="../../../assets/images/classics/rest.png"
        alt=""
        class="rest"
        :class="restStatus ? 'restAni' : ''"
        @click="restChange"
        @tap="restChange"
      />
    </div>
    <div class="tabbar-box" v-else>
      <slot name="search"></slot>
    </div>
    <div class="record-title">
      <div class="table-title">
        <div
          class="table-title-item"
          v-for="(t, i) in tableRecordList"
          :style="{ width: t.width, textAlign: t.center }"
          :key="i"
        >
          <template v-if="t.render">
            {{ t.title }}
          </template>
          <template v-else>
            <slot name="tableTitle" :title="t.title" :i="i">
              {{ t.title }}</slot
            >
          </template>
        </div>
      </div>
      <div class="table-box">
        <div
          class="table-item"
          v-for="(item, i) in tableData.list"
          :key="i"
          :class="`table-item${i % 2 === 0 ? 0 : 1}`"
        >
          <div
            :class="j.index"
            v-for="(j, k) in tableRecordList"
            :key="k"
            :style="{ width: j.width, textAlign: j.center }"
          >
            <template v-if="!j.render">
              <div :style="j.style">
                <img v-if="j.img" :src="j.img.index" alt="" />
                <span v-if="j.leftVal">{{ j.leftVal }}</span>
                {{ item[j.index] }}
                <span v-if="j.rightVal">{{ j.rightVal }}</span>
              </div>
            </template>
            <template v-else>
              <slot :item="j.index" :data="item"></slot>
            </template>
          </div>
        </div>
      </div>
      <div class="common-pagination">
        <el-pagination
          background
          layout="total, pager"
          @current-change="handleCurrentChange"
          :page-size="tableData.pageSize || tableData.pager || pageSize"
          :total="tableData.total || total"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: [String, Number],
      default: 10,
    },
    total: {
      type: [String, Number],
      default: 1,
    },
    renderSearch: {
      type: Boolean,
      default: () => false,
    },
    tableRecordList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    tabList() {
      return [
        {
          name: "全部",
          id: 0,
        },
        {
          name: "盲盒",
          id: 1,
        },
        {
          name: "斗箱",
          id: 2,
        },
        {
          name: "主播",
          id: 3,
        },
        {
          name: "机器人",
          id: 4,
        },
      ];
    },
  },
  data() {
    return {
      allStatus: false,
      otherStatus: false,
      restStatus: false,
      currentPage: 1,
      activeTab: "全部",
    };
  },
  mounted() {
    document.body.addEventListener("click", this.closeDrop);
  },
  destroyed() {
    document.body.removeEventListener("click", this.closeDrop);
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.$emit("pageChange", { page: this.currentPage });
    },
    restChange() {
      this.restStatus = true;
      this.$emit("rest", { page: this.currentPage });
      setTimeout(() => {
        this.restStatus = false;
      }, 1000);
    },
    dropItemClick(index) {
      this.$emit("dropItemClick", this.tabList[index].id);
      this.activeTab = this.tabList[index].name;
    },
    closeDrop() {
      if (this.allStatus || this.otherStatus) {
        this.allStatus = false;
        this.otherStatus = false;
      }
    },
    tabChange(type) {
      if (type === "all") {
        this.allStatus = !this.allStatus;
        this.otherStatus = false;
      } else {
        this.otherStatus = !this.otherStatus;
        this.allStatus = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.record-mod1 {
  width: 100%;
  background: rgba(7, 18, 27, 0.5);
  box-sizing: border-box;
  // padding: 0.4rem 0.3rem;
  padding-top: 0.04rem;

  .tabbar-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .rest {
      width: 20px;
      height: 20px;
      margin-left: 1rem;
      cursor: pointer;
    }

    .restAni {
      animation: rotate 0.5s forwards;
    }
  }

  .record-title {
    width: 100%;
    margin-top: 10px;

    .table-title {
      width: 100%;
      height: 0.3rem;
      background: url(../../../assets/images/classics/table-title.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      .table-title-item {
        font-size: 0.1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #cccccc;
      }
    }

    .table-box {
      width: 100%;

      .table-item {
        display: flex;
        height: 0.4rem;
        line-height: 0.4rem;
        background: #333333;
        box-sizing: border-box;
        font-size: 0.1rem;
        font-weight: 200;
        color: #cccccc;
      }

      .table-item0 {
        box-shadow: inset 0px 0px 10px 5px #33637b;
      }

      .table-item1 {
        box-shadow: inset 0px 0px 8px 2px #338dca;
      }
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes dropAni {
  to {
    opacity: 1;
  }
}
</style>
